import React from 'react';
import Helmet from '../project/Helmet';
import withDataHOC from '../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../constants/globalData';
import CustomerDetailsContent from './customerDetails/CustomerDetailsContent';


/**
 * @fero
 */

class CustomerDetailsPage extends React.PureComponent {
    render() {
        const {[GLOBAL_DATA.CUSTOMER_DETAILS]: customerDetails = {}} = this.props;

        return <React.Fragment>
            <Helmet
                title={customerDetails.name}
            />
            <CustomerDetailsContent/>
        </React.Fragment>;
    }

}

export default withDataHOC([GLOBAL_DATA.CUSTOMER_DETAILS])(CustomerDetailsPage);